import React from 'react';
import Layout from "../components/sections/layout";
import OurBrands from "../components/sections/our-brands";
import Header from "../components/sections/header";
import Footer from "../components/sections/footer";

const Brands = () => {
  return (
    <Layout>
      <Header />
      <OurBrands/>
      <Footer />
    </Layout>
  );
};

export default Brands;
